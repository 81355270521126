import React from 'react'
import TeacherHeader from '../components/TeacherHeader';
import Footer from '../components/Footer';

const RegistrationTeacher = () => {
  return (
    <div>
      <TeacherHeader />
      <div className='titleBox'>
        <h1 className='titleBoxContent'>家庭教師として登録する</h1>
      </div>
      <div className='registrationStudentContainer'>
        <div className='titleBlock'>
            <h2 className='title'>登録にあたっての注意点</h2>
            <ul className='sentenceBoldBlack'>
                <li>【公開】と書かれている項目は、「先生の情報」としてご家庭の方々に公開されます。</li><br/>
                <li>メールアドレスおよび【非公開】と書かれている項目は、ご家庭の方々に公開されることはなく当サービスの運営のために管理されます。</li>
            </ul>
        </div>
        <div className='formBlock'>
          <iframe className='registrationStudentForm' src="https://docs.google.com/forms/d/e/1FAIpQLScDtF-v2-uDwykM5_P9umFTWaiCJZJ236HOnJlCXtRIhY9SHw/viewform?embedded=true" width="100%" title="フォーム">読み込んでいます…</iframe>
        </div>
        <div className='titleBlock'>
            <h2 className='title'>登録後の流れ</h2>
            <p className='sentenceBoldBlack'><span>先生を募集しているご家庭の案内を、週に数回メールで配信します</span>ので、運営からの連絡をお待ちください。<br/><br/>条件を満たしている、気になる募集があればご応募ください。<br/><br/>なお、先生の情報をご覧になったご家庭から直接依頼があった場合は都度ご連絡差し上げます。</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RegistrationTeacher