import React from 'react';
import "../App.css";

const STYLES = [
  "btn--studentRegistration",
  "btn--teacherRegistration",
];

export const Button = ({ 
  children,
  type,
  onClick,
  buttonStyle
}) => {

  const checkButtonStyle = STYLES.includes(buttonStyle)
   ? buttonStyle
   : STYLES[0];

  return (
    <button 
      className={`btn ${checkButtonStyle}`} 
      onClick={onClick} 
      type={type}
    >
      {children}
    </button>
  );
};