export const  StudentGuideData = [
    {
        number: "01",
        title: "生徒情報を登録する",
        description: "まずは上記のボタンからフォーム画面まで行き、学年、指導されたい科目、頻度、時給など、先生に対するご希望の条件を登録してください。",
        guideImg: "",
    },
    {
        number: "02",
        title: "先生の紹介を待つ (無料)",
        description: "条件とマッチした先生たちのリストを、週に数回メールで配信します。その後は、家庭教師のリストの中に気になる方がいればご指名いただけます。ご指名の前に、運営を通じて質問も可能です。また、募集情報をご覧になった先生から応募があった場合は都度ご連絡差し上げます。",
        guideImg: "guide02",
    },
    {
        number: "03",
        title: "先生とのマッチング (有料)",
        description: "先生を指名された後、紹介料のお支払いの確認が取れましたら、家庭教師の連絡先をお渡しします。その後は、ご家庭と先生間にすべてのやりとりをお任せします。つまり、授業料は仲介業者を挟まず、直接先生にお渡しいただけます。",
        guideImg: "",
    },
];