import React from 'react'

const Service = () => {
  return (
    <div className='limitedOffer' id="#limitedOffer">
        <h2 className='titleLimitedOffer'>【期間限定】紹介料が3,000円！秋のキャンペーン実施中</h2>
        <div className='sentenceBoldBlack'>
            <p>紹介料が<span>40%OFF</span>でご利用いただけるキャンペーンを実施中！<br/>条件は期間中（終了未定）に登録・マッチングを完了するだけ。この機会をお見逃しなく！<br/></p>
        </div>
    </div>
  )
}

export default Service