import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import TopPage from './Pages/TopPage';
import Teacher from './Pages/Teacher';
import Price from './Pages/Price';
import RegistrationTeacher from './Pages/RegistrationTeacher';
import RegistrationStudent from './Pages/RegistrationStudent';
import Questions from './Pages/Questions';
import Terms from './Pages/Terms';
import Reissue from './Pages/Reissue';
import Contact from './Pages/Contact';
import Sitemap from './Pages/Sitemap';
import ScrollToTop from "./ScrollToTop";
import Company from './Pages/Company';
import Articles from './Pages/Articles';
import Article0001 from './Pages/Articles/0001';
import Article0002 from './Pages/Articles/0002';
import Article0003 from './Pages/Articles/0003';
import Article0004 from './Pages/Articles/0004';
import Article0006 from './Pages/Articles/0006';
import RecruitPage from './Pages/RecruitPage';
import TeacherPage from './Pages/TeacherPage';
import RecruitSearch from './Pages/RecruitSearch';
import TeacherSearch from './Pages/TeacherSearch';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<TopPage />} />
        <Route path="/teacher" element={<Teacher />} />
        <Route path="/price" element={<Price />} />
        <Route path="/registration-teacher" element={<RegistrationTeacher />} />
        <Route path="/registration-student" element={<RegistrationStudent />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/reissue" element={<Reissue />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/company" element={<Company />} />
        <Route path="/article" element={<Articles />} />
        <Route path="/article/personal-contract" element={<Article0001 />} />
        <Route path="/article/market-price" element={<Article0002 />} />
        <Route path="/article/junior-high-school-entrance-exam" element={<Article0003 />} />
        <Route path="/article/how-to-find-teacher" element={<Article0004 />} />
        <Route path="/article/how-to" element={<Article0006 />} />
        <Route path="/recruit" element={<RecruitPage />} />
        <Route path="/teacherpage" element={<TeacherPage />} />
        <Route path="/recruit_search" element={<RecruitSearch />} />
        <Route path="/teacher_search" element={<TeacherSearch />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
