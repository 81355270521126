import React from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Footer from '../components/Footer';
import Header from '../components/Header';
import Nav from '../components/Nav';
import ArticleList from '../components/ArticleList/ArticleList';
import NewRegistration from '../components/NewRegistration';
import HubspotForm from 'react-hubspot-form'
import RecruitSideBar from '../components/RecruitSideBar';


const RecruitSearch = () => {  
const [quotedRecruitDatas,setQuotedRecruitDatas] = useState([]);
  useEffect(() => {
    fetch("https://sheets.googleapis.com/v4/spreadsheets/1-xI2hmRWSTpZTmCFvWk0NFSgMTTkWOvS1jaVad0WSLA/values/[検索データ]生徒情報用?key=AIzaSyATl2iwtpMqRw5tpImPnMNtSlPDTqVGtmE")
    .then(res => res.json())
    .then(data => setQuotedRecruitDatas(data))
  },[])
  const RecruitDatas = [];
  const [searchParams] = useSearchParams();
  for(var i = 1; i < quotedRecruitDatas.values.length; i++){
    const thisDatas = {};
    thisDatas[quotedRecruitDatas.values[0][0]] = quotedRecruitDatas.values[i][0];
    thisDatas[quotedRecruitDatas.values[0][1]] = quotedRecruitDatas.values[i][1];
    thisDatas[quotedRecruitDatas.values[0][2]] = quotedRecruitDatas.values[i][2];
    thisDatas[quotedRecruitDatas.values[0][3]] = quotedRecruitDatas.values[i][3];
    thisDatas[quotedRecruitDatas.values[0][4]] = quotedRecruitDatas.values[i][4];
    thisDatas[quotedRecruitDatas.values[0][5]] = quotedRecruitDatas.values[i][5];
    thisDatas[quotedRecruitDatas.values[0][6]] = quotedRecruitDatas.values[i][6];
    thisDatas[quotedRecruitDatas.values[0][7]] = quotedRecruitDatas.values[i][7];
    thisDatas[quotedRecruitDatas.values[0][8]] = quotedRecruitDatas.values[i][8];
    thisDatas[quotedRecruitDatas.values[0][9]] = quotedRecruitDatas.values[i][9];
    thisDatas[quotedRecruitDatas.values[0][10]] = quotedRecruitDatas.values[i][10];
    thisDatas[quotedRecruitDatas.values[0][11]] = quotedRecruitDatas.values[i][11];
    thisDatas[quotedRecruitDatas.values[0][12]] = quotedRecruitDatas.values[i][12];
    thisDatas[quotedRecruitDatas.values[0][13]] = quotedRecruitDatas.values[i][13];
    thisDatas[quotedRecruitDatas.values[0][14]] = quotedRecruitDatas.values[i][14];
    thisDatas[quotedRecruitDatas.values[0][15]] = quotedRecruitDatas.values[i][15];
    thisDatas[quotedRecruitDatas.values[0][16]] = quotedRecruitDatas.values[i][16];
    thisDatas[quotedRecruitDatas.values[0][17]] = quotedRecruitDatas.values[i][17];
    thisDatas[quotedRecruitDatas.values[0][18]] = quotedRecruitDatas.values[i][18];
    RecruitDatas.push(thisDatas)
  }
  const filteredRecruitDatas = RecruitDatas.filter(RecruitDatas => RecruitDatas.id === searchParams.get("id"))

return (
    <div className='recruitePageContainer'>
      <Header />
      <div className='pcArea'><Nav /></div>
        <div className='mainSection'>
          <div className='leftContents'>
            <div className='pcArea'><RecruitSideBar /></div>
            <div className='pcArea'><NewRegistration /></div>
          </div>
          <div className='mainContents'>
            <div className='apply'>
                <h1>この求人情報に応募する</h1>
                <h2>先生情報を登録済みの方</h2>
                <p>以下のフォームに情報を入力して、送信してください。</p>
                <HubspotForm
                  portalId='22770219'
                  formId='f4b2c570-973e-4430-8b9b-9072cce6531a'
                  onSubmit={() => console.log('Submit!')}
                  onReady={(form) => console.log('Form ready!')}
                  loading={<div>Loading...</div>}
                />
                <p>※教師IDを忘れた場合<br/>
                情報登録をされた際の、運営からの完了メールをご確認ください。<br/>家庭教師検索ページで検索をして、もしご自身の情報と一致するページが見つかれば、そちらに記載されている教師IDがあなたの教師IDです。</p>
                <h2>先生情報を登録済みの方</h2>
                <p>
                  <span>求人の応募は、パーソナル（当サイト）で家庭教師登録をしていることが条件です。</span><br/>
                  まずは<Link to="/registration-teacher" target="_blank" className='toLink'>家庭教師登録ページ</Link>よりご登録ください。
                </p>
            </div>
            <div className='recruitInfo'>
                <div className='tableTitle'>求人情報の詳細</div>
                {filteredRecruitDatas.map((value, key) => {
                    return (
                        <div className='tableItems'>
                          <dl className='tableItem'>
                                <dt>生徒ID</dt>
                                <dd>{value.id}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>都道府県</dt>
                                <dd>{value.prefecture}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>市区町村</dt>
                                <dd>{value.address}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>最寄駅</dt>
                                <dd>{value.station}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>生徒の学年</dt>
                                <dd>{value.grade}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>生徒の性別</dt>
                                <dd>{value.studentssex}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>指導を希望する科目</dt>
                                <dd>{value.subjects}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>時給</dt>
                                <dd>{value.wage}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>希望する先生の性別</dt>
                                <dd>{value.teacherssex}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>頻度・時間</dt>
                                <dd>{value.frequency_time}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>指導を希望する曜日</dt>
                                <dd>{value.days}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>先生への質問</dt>
                                <dd>{value.question}</dd>
                            </dl>
                            <dl className='tableItem'>
                                <dt>現在の応募数</dt>
                                <dd>{value.apply}件</dd>
                            </dl>
                        </div>
                    )
                })}
            </div>
          </div>
          <div className='rightContents'>
            <ArticleList />
          </div>
        </div>
      <Footer />
    </div>
  )


}

export default RecruitSearch