import React from 'react'
import { Link } from "react-router-dom";
import CompanyLogo from "../Images/CompanyLogo.png";

const TeacherHeader = () => {
  return (
    <div className='headerSpace'>
      <div className='header'>
        <div className='headerSection'>
          <Link to="/#top" className='' style={{ textDecoration: 'none' }}>
            <img src={CompanyLogo} className="CompanyLogo" alt="" />
          </Link>
          <div className='headerLinks'>
            <Link to="/registration-teacher" className='toTeacher' style={{ textDecoration: 'none' }}>
              <p>家庭教師として登録する</p>
            </Link>
            <Link to="/#top" className='toStudentRegistration pcArea' style={{ textDecoration: 'none' }}>
              <p>生徒用のページに戻る</p>
            </Link>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default TeacherHeader