import React from 'react'
import { VoiceData } from './VoiceData'

const  Voice = () => {
    return (
        <div className='voiceContainer'>
            <h2 className='heading' id="voice">#保護者の声</h2>
            <ul className='voiceList'>
                {VoiceData.map((value, key) => {
                    return(
                        <li className='voiceContent' key={key}>
                            <div className='voiceHeading'>
                                <p>{value.voiceHeading}</p>
                                <section></section>
                            </div>
                            <div className='voiceText'>
                                <p>{value.voiceText}</p>
                            </div>
                            <div className='voiceFrom'>
                                <div className='voiceInfo'>
                                    <p>{value.voiceInfo}</p>
                                </div>
                                <div className='voiceTag'>
                                    <p>#{value.voiceTag}</p>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
  )
}

export default Voice