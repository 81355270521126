import React from 'react'
import { useState, useEffect } from "react"

function NewRegistration() {
    const [datas, setDatas] = useState([])
    useEffect(() => {
        fetch("https://sheets.googleapis.com/v4/spreadsheets/1-xI2hmRWSTpZTmCFvWk0NFSgMTTkWOvS1jaVad0WSLA/values/新着情報用?key=AIzaSyATl2iwtpMqRw5tpImPnMNtSlPDTqVGtmE")
        .then(res => res.json())
        .then(data => setDatas(data))
    },[])
    const assignDatas = [];
    for(var i = 1; i < datas.values.length; i++){
        const thisDatas = {};
        thisDatas[datas.values[0][0]] = datas.values[i][0];
        thisDatas[datas.values[0][1]] = datas.values[i][1];
        thisDatas[datas.values[0][2]] = datas.values[i][2];
        thisDatas[datas.values[0][3]] = datas.values[i][3];
        assignDatas.push(thisDatas)
    }

    return (
        <div className='newRegistration'>
            <div className='heading'>
                <p>新着情報<br/>(最新20件)</p>
            </div>
            <ul className='newsList'>
                {assignDatas.map((value, key) => {
                    return (
                        <li className={value.color}>
                            <div>{value.text}</div>
                            <div>{value.date}</div>
                        </li>
                    )
                })}
            </ul>
        </div>
     )
}

export default NewRegistration