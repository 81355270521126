import React from 'react'
import personalContract from "../../Images/personalContract.jpg";
import marketPrice from "../../Images/marketPrice.jpg";
import { Link } from "react-router-dom";

const ArticleList = () => {
  return (
    <div className='otherArticles'>
        <div className='heading'>
            <p>よく読まれている記事</p>
        </div>
        <ul className='list'>
          <Link to="/article/personal-contract">
            <li className='items'>
              <div className='image'><img src={personalContract} alt="" /></div>
              <div className='articleTitle'><p>家庭教師の個人契約とは？メリットや注意点を解説</p></div>
            </li>
          </Link>
          <Link to="/article/market-price">
            <li className='items'>
              <div className='image'><img src={marketPrice} alt="" width="100%" /></div>
              <div className='articleTitle'><p>家庭教師の費用相場は？個人契約についてもくわしく解説！</p></div>
            </li>
          </Link>
        </ul>
    </div>
  )
}

export default ArticleList