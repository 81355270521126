import React from 'react';
import eyecatchTeacher from "../Images/eyecatchTeacher.jpg";
import eyecatchTeacherBig from "../Images/eyecatchTeacherBig.jpg";
import TeacherHeader from '../components/TeacherHeader';
import TeacherButton from '../components/TeacherButton';
import TeacherGuide from '../components/TeacherGuide';
import Footer from '../components/Footer';

const Teacher = () => {
    return (
      <div>
        <TeacherHeader />
          <img src={eyecatchTeacher} className="Eyecatch smartphoneArea" alt="" />
          <img src={eyecatchTeacherBig} className="EyecatchBig pcArea" alt="" />
          <div className='MiniContainer'>
            <div className='titleBlock'>
              <h2 className='title'>パーソナルで家庭教師になるには</h2>
              <div className='sentenceBoldBlack'>
                <p>まずはプロフィールの登録をお願いします。</p>
                <p>【公開】と横に記載されている項目（=個人情報以外の項目）は、ご家庭が閲覧されます。</p>
              </div>
            </div>
            <TeacherButton />
          </div>
        <TeacherGuide />
        <Footer />
      </div>
    );
  };
  
  export default Teacher