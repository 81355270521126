export const  FooterData = [
    {
        title: "よくあるご質問",
        link: "/questions",
    },
    {
        title: "料金システムについて",
        link: "/price",
    },
    {
        title: "生徒情報を新規登録する",
        link: "/registration-student",
    },
    {
        title: "家庭教師・生徒の登録情報を修正する",
        link: "/reissue",
    },
    {
        title: "お客様の声",
        link: "/#voice",
    },
    {
        title: "指導開始までの流れ",
        link: "/#guide",
    },
    {
        title: "TOPページ（家庭用）に戻る",
        link: "/#top",
    },
    {
        title: "お問い合わせフォーム",
        link: "/contact",
    },
    {
        title: "家庭教師として登録したい方はこちら",
        link: "/registration-teacher",
    },
    {
        title: "運営者情報",
        link: "/company",
    },
    {
        title: "プライバシーポリシー",
        link: "/terms",
    },
    {
        title: "サイトマップ",
        link: "/sitemap",
    },
];