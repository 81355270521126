import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <div>
      <Header />
      <div className='titleBox'>
        <h1 className='titleBoxContent'>お問い合わせフォーム</h1>
      </div>
      <div className='termsContainer'>
        <div>
            <h2 className='title'>お問合わせはこちら</h2>
            <div className='formBlock'>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc4od2BdnXqZOx06v7ex3oWUppZrJ7Sii88pbHLvaxGVjeYMw/viewform?embedded=true" width="100%" height="1138" frameborder="0" marginheight="0" marginwidth="0" title="フォーム">読み込んでいます…</iframe>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact