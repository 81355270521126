import React from 'react'
import Eyecatch from "../Images/Eyecatch.jpeg";
import EyecatchBig from "../Images/EyecatchBig.jpeg";
import Footer from '../components/Footer';
import StudentGuide from '../components/StudentGuide';
import Header from '../components/Header';
import PriceTable from '../components/PriceTable';
import Voice from '../components/Voice';
import Nav from '../components/Nav';
import ArticleList from '../components/ArticleList/ArticleList';
import NewRegistration from '../components/NewRegistration';
import Service from '../components/Service';
import RecruitSideBar from '../components/RecruitSideBar';
import TeacherSideBar from '../components/TeacherSideBar';

const TopPage = () => {
  return (
    <div className='topPageContainer'>
      <Header />
      <div className='pcArea'><Nav /></div>
        <div className='mainSection'>
          <div className='leftContents'>
            <div className='pcArea'><NewRegistration /></div>
            <div className='pcArea'><RecruitSideBar /></div>
            <div className='pcArea'><TeacherSideBar /></div>
          </div>
          <div className='mainContents'>
            <h1>
              <img src={Eyecatch} className="Eyecatch smartphoneArea" alt="" />
              <img src={EyecatchBig} className="EyecatchBig pcArea" alt="" />
            </h1>
            <Service />
            <div className='smartphoneArea'><NewRegistration /></div>
            <StudentGuide />
            <div className='pcPriceContainer pcArea'>
            <div className='titleBlock'>
                <h2 className='title'>料金の目安</h2>
                <div className='pcPriceTable'>
                  <PriceTable />
                </div>
            </div>
            </div>
          </div>
          <div className='rightContents'>
            <ArticleList />
            <Voice />
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default TopPage