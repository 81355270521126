import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

const RegistrationStudent = () => {
  return (
    <div>
      <Header />
      <div className='titleBox'>
        <h1 className='titleBoxContent'>生徒情報を登録する</h1>
      </div>
      <div className='registrationStudentContainer'>
        <div className='titleBlock'>
            <h2 className='title'>情報登録にあたって</h2>
            <ul className='sentenceBoldBlack'>
                <li>【公開】と書かれている項目は、「募集情報」として家庭教師の方々に公開されます。</li>
                <li>メールアドレスおよび【非公開】と書かれている項目は、家庭教師の方々に公開されることはなく当サービスの運営のために管理されます。</li>
            </ul>
        </div>
        <div className='formBlock'>
          <iframe className='registrationStudentForm' src="https://docs.google.com/forms/d/e/1FAIpQLSfxlFfU_MfSJsqvufFwWl1d9F2MMF26YApyk5IFiwbafnYZ8A/viewform?embedded=true" width="100%" title="フォーム">読み込んでいます…</iframe>
        </div>
        <div className='titleBlock'>
            <h2 className='title'>登録後の流れ</h2>
            <p className='sentenceBoldBlack'>条件とマッチした<span>家庭教師のリストを、週に数回メールで配信します</span>ので、運営からの連絡をお待ちください。<br/>その後は、家庭教師のリストの中に気になる方がいればご指名いただけます。ご指名の前に、運営を通じて質問も可能です。<br/>なお、募集情報をご覧になった先生から応募があった場合は都度ご連絡差し上げます。</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RegistrationStudent