export const  VoiceData = [
    {
        voiceHeading: "紹介料がとにかく安い",
        voiceText: "昨日紹介頂いたM先生と面談しました。他のサイトと比べてもかなり安い料金設定で、本当に感謝しています。紹介料の3,000円はかなりお得だと思います。取り急ぎお礼まで。",
        voiceInfo: "※勉強の秋キャンペーンをご利用されたお客様　千葉県・Y様・小学5年生",
        voiceTag: "中学受験・算数",
    },
    {
        voiceHeading: "個人契約サポートがあり安心",
        voiceText: "ネットで紹介料が安いのは嬉しい一方、心配な部分もありましたが、登録からスムーズに契約まで至りました。定期的に先生の情報が送られてくるのは、自分から検索して探す手間もなく手軽でした。",
        voiceInfo: "東京都・O様・高校2年生",
        voiceTag: "定期試験対策・英語",
    },
    {
        voiceHeading: "対応が迅速だった",
        voiceText: "祝日の登録にもかかわらず、すぐに先生を紹介いただけた。",
        voiceInfo: "東京都・U様・小学6年生",
        voiceTag: "中学受験・全教科",
    },
    {
        voiceHeading: "無事契約できました。",
        voiceText: "紹介して頂いた先生と面談致しました。出身中学が同じという先生を選ばせていただき、面談を通じて、無事契約の運びとなりました。ありがとうございました。",
        voiceInfo: "福岡県・Y様・中学3年生",
        voiceTag: "定期試験対策・英語,数学等",
    },
    {
        voiceHeading: "総合的に見て一番良かった",
        voiceText: "面談の日取りまで斡旋してくれる。他社も先生を何人も閲覧したが、手続きの分かりにくさや料金など総合的に見てパーソナルさんが一番良いと感じた。",
        voiceInfo: "埼玉県・I様・高校3年生",
        voiceTag: "大学受験対策・複数科目",
    },
];