import React from 'react'
import { Link } from "react-router-dom";
import Footer from '../components/Footer'
import Header from '../components/Header'

const Reissue = () => {
  return (
    <div>
        <Header />
        <div className='titleBox'>
            <h1 className='titleBoxContent'>登録した情報の編集（生徒・家庭教師）</h1>
        </div>
        <div className='reissueContainer'>
        <div>
            <h2 className='title'>登録情報の編集について</h2>
            <div className='sentenceBoldBlack'>
                <p>登録情報の変更は以下の通りです。ご登録完了の際ご記入いただいたメールアドレス宛に<br/>「Googleフォーム（forms-receipts-noreply@google.com）」からメール届いております。<br/>メール内に、【回答を編集】というボタンがございますので、そちらから情報の編集をお願いいたします。<br/><br/>削除をご希望の方、もしくはメールが見つからない方は、
                <Link to="/contact" style={{ fontWeight: 'bold' }}>こちら</Link>
                の問い合わせフォームからご連絡くださいませ。</p>
            </div>
        </div>
      </div>
        <Footer />
    </div>
  )
}

export default Reissue