

export const  TeacherGuideData = [
    {
        number: "01",
        title: "運営からのメール案内を受ける",
        description: "当サイトでは、生徒の新規募集は全てメールでおこないます。早期に申し込まれた先生の採用率が高い傾向にありますので、お見逃しのないようご注意ください。",
        guideImg: "teacherGuide01",
    },
    {
        number: "02",
        title: "家庭教師の募集に応募する",
        description: "エリアや給与など、ご自身の条件に合う募集をメールで受け取った時は、メールの案内に従って応募をしてください。応募された先生の情報を生徒様にお伝えし、指導を希望されるか運営が確認いたします。",
    },
    {
        number: "03",
        title: "生徒との個人契約",
        description: "先ご家庭があなたの指導を希望された場合、パーソナルから指導条件や指導内容等の情報をメールでお知らせします。その後、ご家庭と連絡を取り合っていただき、必要に応じて面談や体験授業を行い、指導開始となります。",
    },
];