import React from 'react'
import { useState, useEffect } from "react"
import { Link } from 'react-router-dom';

const RecruitSideBar = () => {
  //fetchでSSからデータを取ってくる
  const [quotedRecruitDatas,setQuotedRecruitDatas] = useState([]);
  useEffect(() => {
    fetch("https://sheets.googleapis.com/v4/spreadsheets/1-xI2hmRWSTpZTmCFvWk0NFSgMTTkWOvS1jaVad0WSLA/values/[サイドバー]生徒情報用?key=AIzaSyATl2iwtpMqRw5tpImPnMNtSlPDTqVGtmE")
    .then(res => res.json())
    .then(data => setQuotedRecruitDatas(data))
  },[])
  const RecruitDatas = [];
  for(var i = 1; i < quotedRecruitDatas.values.length; i++){
    const thisDatas = {};
    thisDatas[quotedRecruitDatas.values[0][0]] = quotedRecruitDatas.values[i][0];
    thisDatas[quotedRecruitDatas.values[0][1]] = quotedRecruitDatas.values[i][1];
    thisDatas[quotedRecruitDatas.values[0][2]] = quotedRecruitDatas.values[i][2];
    thisDatas[quotedRecruitDatas.values[0][3]] = quotedRecruitDatas.values[i][3];
    thisDatas[quotedRecruitDatas.values[0][4]] = quotedRecruitDatas.values[i][4];
    thisDatas[quotedRecruitDatas.values[0][5]] = quotedRecruitDatas.values[i][5];
    thisDatas[quotedRecruitDatas.values[0][6]] = quotedRecruitDatas.values[i][6];
    thisDatas[quotedRecruitDatas.values[0][7]] = quotedRecruitDatas.values[i][7];
    thisDatas[quotedRecruitDatas.values[0][8]] = quotedRecruitDatas.values[i][8];
    thisDatas[quotedRecruitDatas.values[0][9]] = quotedRecruitDatas.values[i][9];
    thisDatas[quotedRecruitDatas.values[0][10]] = quotedRecruitDatas.values[i][10];
    thisDatas[quotedRecruitDatas.values[0][11]] = quotedRecruitDatas.values[i][11];
    thisDatas[quotedRecruitDatas.values[0][12]] = quotedRecruitDatas.values[i][12];
    thisDatas[quotedRecruitDatas.values[0][13]] = quotedRecruitDatas.values[i][13];
    thisDatas[quotedRecruitDatas.values[0][14]] = quotedRecruitDatas.values[i][14];
    thisDatas[quotedRecruitDatas.values[0][15]] = quotedRecruitDatas.values[i][15];
    thisDatas[quotedRecruitDatas.values[0][16]] = quotedRecruitDatas.values[i][16];
    thisDatas[quotedRecruitDatas.values[0][17]] = quotedRecruitDatas.values[i][17];
    thisDatas[quotedRecruitDatas.values[0][18]] = quotedRecruitDatas.values[i][18];
    RecruitDatas.push(thisDatas)
  }

  return (
    <div className='recruitSideBarContainer'>
      <div className='heading'>
        <p>現在募集中の求人！</p>
      </div>
      <div className='contents'>
          {RecruitDatas.map((value, key) => {
            return (
              <ul className='postSection'>
                <Link to={"/recruit?id="+value.id}>
                  <li className='posts'>
                    <p className='date'><span>New!!</span> {value.startdate}</p>
                    <p className='area'>{value.address}の{value.grade}！（時給：{value.wage}）</p>
                  </li>
                </Link>
              </ul>
            )
          })}
      </div>
    </div>
  )
}

export default RecruitSideBar