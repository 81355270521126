import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import personalContract from "../../Images/personalContract.jpg";
import ArticleListWithout0001 from '../../components/ArticleList/ArticleListWithout0001'
import NewRegistration from '../../components/NewRegistration';
import NewRegistars from '../../components/NewRegistars';
import Voice from '../../components/Voice';
import StudentButton from '../../components/StudentButton';

const Article0001 = () => {
  return (
    <div>
      <Header />
        <div className='articlePage'>
          <div className='articleContainer'>
            <div className='articleContent'>
              <p className='articleDate'>2022年10月14日</p>
              <h1 className='articleTitle'>家庭教師の個人契約とは？メリットや注意点を解説</h1>
              <h2 className='articleEyecatch'><img src={personalContract} alt="" width="100%" /></h2>
              <h3 className='toc'>
                <ul>
                  <li>【目次】</li>
                  <li>1. そもそも家庭教師の個人契約とは何か？</li>
                  <li>2. 個人契約の利点</li>
                  <li>3. 家庭教師の個人契約は月いくらかかる？</li>
                  <li>4. 家庭教師のマッチングサイトはどこを使えばいい？</li>
                </ul>
              </h3>
              <div className='articleBody'>
                <h3 className='articleLead'>
                  <p>そもそも家庭教師の個人契約とは何か？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'><p>家庭教師の個人契約とは、先生と生徒が"直接"契約をすることです。これまでの家庭教師は一般的に、"家庭教師センター"と呼ばれるような仲介業者を通して、先生と生徒の契約が行われていました。<br/><br/>しかし最近では、先生・生徒両方にとって金銭的なメリットが大きい個人契約が主流になりつつあります。では、具体的にどのようなメリットがあるのでしょうか？</p>
                  </div>
                  <div className='fyi'>
                    <p className='heading'>補足：家庭教師センターとは？</p>
                    <p className='text'>家庭教師センターとは家庭教師派遣会社のことです。<br/>家庭教師センターは、個人契約に比べて授業料は高い傾向にあります。その理由としては、講師への給料に加え、マージン料、広告費、講師募集費用などが、ご家庭が払う月謝に含まれるからです。その他に、入会金や管理費がかかるため個人契約に比べると割高になるでしょう。<br/><br/>ご家庭にとって値段が高いのはネックですがもちろんそれ相応の価値もあります。たとえば、家庭教師の個人契約で最もデメリットとしてあげられるのはご家庭生徒間でのトラブルです。センターではトラブルに対する対応を一括に請け負ってもらえるため、ご家庭の負担や煩わしさは大きく軽減されるでしょう。</p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>個人契約のメリット</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                      個人契約の利点は主に3つです。<br/><br/>
                      <h4>時給が安い（費用が安く済む）</h4><br/>
                      個人契約では、仲介手数料の他に管理費や入会金などもかかりません。家庭教師センターが仲介する場合は、個人契約の1.5〜2倍程度の費用がかかります。<br/><br/>
                      <h4>契約内容を柔軟に決められる</h4><br/>
                      センターの場合では家庭教師を頼んでから実際に派遣されるまで、様々な手続きをした上で通常二週間程度はかかります。<br/><br/>しかし、個人契約の場合、契約したその日から授業をしてもらうことができます。また、「定期テスト前と長期休みは週二回にしてほしい」「今週は旅行に行くから休みにしてほしい」といった場合もすぐに対応できる柔軟さは魅力でしょう。<br/><br/>
                      <h4>教師を自分で選べる</h4><br/>
                      センターの場合は要望を伝えた上でその条件にあった教師を紹介してもらう形式ですが、個人契約の場合は条件に合わせてたくさんの先生候補から依頼をかけることができます。
                    </p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>家庭教師の個人契約は月いくらかかる？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                    授業一時間あたり、個人契約なら2,000〜3,000円、家庭教師センターなら4,000〜6,000円程度の費用がかかると想定すると良いでしょう。<br/><br/>これらは、受験目的や学歴などによって大きく変動もします。難関校受験などの指導を依頼する場合には授業料は高くなる傾向もあります。自分の目的やレベルにあった家庭教師を選び、費用とのバランスを取りましょう。プロ家庭教師の場合、さらに値段が上がります。
                    </p>
                  </div>
                </div>
                <StudentButton />
              </div>
            </div>
            <div className='rightContents'>
              <ArticleListWithout0001 />
              <NewRegistration />
              <div className='pcArea'><NewRegistars /></div>
              <Voice />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default Article0001