import React from 'react'
import { StudentGuideData } from './StudentGuideData';
import StudentButton from './StudentButton';

const StudentGuide = () => {
  return (
    <div className='guideBlock'>
        <div className='guideContainer'>
            <div className='titleBlock'>
                <StudentButton />
                <ul className='guideContent'>
                    {StudentGuideData.map((value, key) => {
                        return (
                            <li className='guideListItem' key={key}>
                                <div className='guideContentLeft'>
                                    <h3 className='guideListTitle'>
                                        <div id="number">{value.number}</div>
                                        <div id="title">{value.title}</div>
                                    </h3>
                                    <div id="description">{value.description}</div>
                                </div>
                                <div  className='guideImgs'>
                                    <img src={`images/${value.guideImg}.png`} className="guideImg" alt="" />
                                </div>
                            </li>
                        )
                    })}
                    <div className='smartphoneArea'>
                    </div>
                    <StudentButton />
                </ul>
            </div>
        </div>
    </div>
  )
}

export default StudentGuide