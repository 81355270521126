import React from 'react'
import { useState, useEffect } from "react"
import { Link } from 'react-router-dom';

const TeacherSideBar = () => {
  //fetchでSSからデータを取ってくる
  const [quotedTeacherDatas,setQuotedTeacherDatas] = useState([]);
  useEffect(() => {
    fetch("https://sheets.googleapis.com/v4/spreadsheets/1-xI2hmRWSTpZTmCFvWk0NFSgMTTkWOvS1jaVad0WSLA/values/[サイドバー]先生情報?key=AIzaSyATl2iwtpMqRw5tpImPnMNtSlPDTqVGtmE")
    .then(res => res.json())
    .then(data => setQuotedTeacherDatas(data))
  },[])
  const TeacherDatas = [];
  for(var i = 1; i < quotedTeacherDatas.values.length; i++){
    const thisDatas = {};
    thisDatas[quotedTeacherDatas.values[0][0]] = quotedTeacherDatas.values[i][0];
    thisDatas[quotedTeacherDatas.values[0][1]] = quotedTeacherDatas.values[i][1];
    thisDatas[quotedTeacherDatas.values[0][2]] = quotedTeacherDatas.values[i][2];
    thisDatas[quotedTeacherDatas.values[0][3]] = quotedTeacherDatas.values[i][3];
    thisDatas[quotedTeacherDatas.values[0][4]] = quotedTeacherDatas.values[i][4];
    thisDatas[quotedTeacherDatas.values[0][5]] = quotedTeacherDatas.values[i][5];
    thisDatas[quotedTeacherDatas.values[0][6]] = quotedTeacherDatas.values[i][6];
    thisDatas[quotedTeacherDatas.values[0][7]] = quotedTeacherDatas.values[i][7];
    thisDatas[quotedTeacherDatas.values[0][8]] = quotedTeacherDatas.values[i][8];
    thisDatas[quotedTeacherDatas.values[0][9]] = quotedTeacherDatas.values[i][9];
    thisDatas[quotedTeacherDatas.values[0][10]] = quotedTeacherDatas.values[i][10];
    thisDatas[quotedTeacherDatas.values[0][11]] = quotedTeacherDatas.values[i][11];
    thisDatas[quotedTeacherDatas.values[0][12]] = quotedTeacherDatas.values[i][12];
    thisDatas[quotedTeacherDatas.values[0][13]] = quotedTeacherDatas.values[i][13];
    thisDatas[quotedTeacherDatas.values[0][14]] = quotedTeacherDatas.values[i][14];
    thisDatas[quotedTeacherDatas.values[0][15]] = quotedTeacherDatas.values[i][15];
    thisDatas[quotedTeacherDatas.values[0][16]] = quotedTeacherDatas.values[i][16];
    thisDatas[quotedTeacherDatas.values[0][17]] = quotedTeacherDatas.values[i][17];
    thisDatas[quotedTeacherDatas.values[0][18]] = quotedTeacherDatas.values[i][18];
    thisDatas[quotedTeacherDatas.values[0][19]] = quotedTeacherDatas.values[i][19];
    thisDatas[quotedTeacherDatas.values[0][20]] = quotedTeacherDatas.values[i][20];
    TeacherDatas.push(thisDatas)
  }

  return (
    <div className='teacherSideBarContainer'>
      <div className='heading'>
        <p>いま生徒を募集中の<br/>家庭教師！</p>
      </div>
      <div className='contents'>
          {TeacherDatas.map((value, key) => {
            return (
              <ul className='postSection'>
                <Link to={"/teacherpage?id="+value.id}>
                  <li className='posts'>
                    <p className='name'>{value.name}先生</p>
                    <section></section>
                    <p className='possible_prefecture'>{value.possible_prefecture}</p>
                    <p className='final_edu'>{value.final_edu}</p>
                    <p className='wage'>希望時給：{value.wage}</p>
                    <p className='short_profile'>{value.short_profile}</p>
                  </li>
                </Link>
              </ul>
            )
          })}
      </div>
    </div>
  )
}

export default TeacherSideBar