import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import marketPrice from "../../Images/marketPrice.jpg";
import ArticleListWithout0002 from '../../components/ArticleList/ArticleListWithout0002'
import NewRegistration from '../../components/NewRegistration';
import NewRegistars from '../../components/NewRegistars';
import Voice from '../../components/Voice';
import StudentButton from '../../components/StudentButton';

const Article0002 = () => {
  return (
    <div>
      <Header />
        <div className='articlePage'>
          <div className='articleContainer'>
            <div className='articleContent'>
              <p className='articleDate'>2022年10月19日</p>
              <h1 className='articleTitle'>家庭教師の費用相場は？個人契約についてもくわしく解説！</h1>
              <h2 className='articleEyecatch'><img src={marketPrice} alt="" width="100%" /></h2>
              <h3 className='toc'>
                <ul>
                  <li>【目次】</li>
                  <li>1. 家庭教師の料金システムは？</li>
                  <li>2. 家庭教師にかかる費用の相場は？</li>
                  <li>3. 時給はどのように決める？</li>
                  <li>4. 支払うタイミングと支払い方法について</li>
                </ul>
              </h3>
              <div className='articleBody'>
                <h3 className='articleLead'>
                  <p>家庭教師の料金システムは？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'><p>まず、家庭教師の相場を知るために、料金システムについて知る必要があります。料金システムは主に以下の2種類です。<br/><br/>
                  ①1時間の指導料○○円×1か月の合計指導時間＝月謝<br/>
                  ②1コマの指導料○○円×1か月の合計コマ数＝月謝<br/><br/>
                  この式の「指導料」には、個人契約であれば先生の時給がそのまま入ります。仲介の家庭教師センターがいれば仲介手数料が上乗せされた金額となります。<br/><br/>では、例えば現役大学生に中学受験指導や定期試験対策を任せる場合の平均的な金額はいくらになるのでしょうか。</p>
                  </div>
                  <div className='fyi'>
                    <p className='heading'>補足：細かな条件による料金の違い</p>
                    <p className='text'>家庭教師の料金は条件や環境によっても変化します。上記の個人契約 or 仲介もその一つです。<br/>他には、講師の経歴、生徒の年齢、地域によって指導料には差が生まれることがあります。</p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>家庭教師にかかる費用の相場は？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                      例として、現役大学生の家庭教師に中学生の定期試験対策をお願いしたと想定します。その場合、料金相場（1時間あたり）はおよそ以下の通りです。<br/><br/>
                      個人契約の場合：2,000円〜3,000円<br/>
                      家庭教師センターの場合：4,000円〜6,000円<br/><br/>
                      これらは、いろいろな条件によって幅がありますし、他のいくつかのサイトではもっと範囲を広く紹介していることもあります。しかし、実際にはそこまで大きな幅はなく、我々の個人契約サービスもほとんどが時給2,000円前後で決定しています。ここに、家庭教師の交通費を足すと、現実的な月謝が想定できます。<br/><br/>
                      例）時給2,000円 一回2時間 往復交通費1,000円の場合 月4回を個人契約した場合<br/>
                      月謝 = (2,000円×2時間+1,000円)×4 = 20,000円 となります。
                    </p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>時給はどのように決める？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                      では、肝心の時給はどのように決めれば良いのでしょうか？<br/><br/>
                      これも個人契約か家庭教師センターかによりますが、いずれの場合も先生側からの希望時給があれば、その範囲内で決めるのが一般的でしょう。<br/><br/>
                      何も条件がない場合は、やはり相場の目安は2,000円ですので、まずは2,000円+交通費から交渉を始めることを推奨します。
                    </p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>支払うタイミングと支払い方法について</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                      最後に、月謝を支払うタイミングと方法についてです。<br/><br/>
                      まず、家庭教師センターを利用されている場合は、規定の支払い方法に則って家庭教師センターにお支払いしましょう。<br/><br/>
                      個人契約の場合は、以下の通りです。<br/><br/>
                      <h4>支払いタイミング</h4><br/>
                      都度支払いか、月末にまとめて支払うかの大きく2パターンに分かれます。我々のお客様では、おおよそ半々に分かれるようですので、先生と相談して決めるのが良さそうです。指導スケジュールが不定期な場合は、都度払いの方がご家庭・先生どちらにとっても良いかもしれませんね。<br/><br/>
                      <h4>支払い方法</h4><br/>
                      手渡しもしくは銀行振込が多いようです。最近は、PayPayやLINEPayなどオンライン送金の方法も充実していますから、先生との折り合いがつけばどんな方法でも問題ありません。
                    </p>
                  </div>
                  <StudentButton />
                </div>
              </div>
            </div>
            <div className='rightContents'>
              <ArticleListWithout0002 />
              <NewRegistration />
              <div className='pcArea'><NewRegistars /></div>
              <Voice />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default Article0002