import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

const Terms = () => {
  return (
    <div>
      <Header />
      <div className='titleBox'>
        <h1 className='titleBoxContent'>プライバシーポリシー</h1>
      </div>
      <div className='termsContainer'>
        <div className='termsContent'>
          <h3>1. 家庭教師の個人契約パーソナル サービス内容について</h3>
          <p>(1)家庭教師の個人契約パーソナル（以下、当サイトと呼ぶ）は、登録教師に家庭教師先を紹介するように努めます。ただし、当サイトは、家庭教師先の紹介を保証いたしません。<br/>(2)当サイトは、前項の目的を達するため、登録教師及び登録生徒の情報を、個人情報が特定できない範囲でユーザーに提供することがあります。<br/>(3)当サイトは、登録申請者および登録教師による本サービスについての感想等を、個人が特定されないように改変した上、本サイトで引用することがあります。</p><br/>
          
          <h3>2. 求人について</h3>
          <p>家庭教師のご依頼の前に必ずメールのやり取りを遡り、求職者の希望する待遇内容をご確認下さい。ご依頼内容は、求人者がそれらを確認し、一切の責任を持っていただきます。</p><br/>
          
          <h3>3. 家庭教師の給与について</h3>
          <p>授業料・交通費の金額および支給方法については求人者と求職者が直接話し合って決めていただきます。その際、当サイトは一切関与いたしません。</p><br/>

          <h3>4. サービス運営の廃止について</h3>
          <p>当サイトは、事業の存続が不可能と判断するに足るやむを得ない事情があった場合、事前の通知無しに現在行っているサービスおよび将来行う予定であるサービスを中止することや、その修正・変更を加える場合があります。</p><br/>
          
          <h3>5. 禁止事項</h3>
          <p>当サイトの利用にあたって、以下の行為を禁止します。<br/>(1)虚偽の情報を登録・記入・送信すること。<br/>(2)紹介前に連絡先やSNS(ソーシャルネットワーキングサービス)のアカウント情報、本名等の本人特定につながる情報を伝える行為。<br/>(3)公序良俗、法令に反する行為。<br/>(4)当サイトから得られる情報を、家庭教師の契約締結以外の目的で用いること。<br/>(5)その他、当サイトが不適切と判断する一切の行為。</p><br/>

          <h3>6. 禁止事項</h3>
          <p>当サイトのサービスによって、登録申請者または登録教師が損害を受けた場合においても、当サイトは一切責任を負いません。当サイトはあくまで情報を提供をするのみです。面談から指導開始後まで、問題が起こらないよう、細心の注意を払うようお願いいたします。</p><br/>

          <h3>7. 登録情報の削除について</h3>
          <p>(1)登録教師から登録情報の削除依頼があった場合、当サイトは登録を一切削除いたします。<br/>(2)登録教師から、登録内容の変更依頼があった場合、当サイトは審査の上、適当なものと認めた場合には変更します。<br/>(3)当サイトは、理由の如何を問わず、登録教師の登録内容の全部または一部を削除もしくは変更する権利を有します。<br/>(3)当サイトは、理由の如何を問わず、登録教師が当該サービスの利用を停止もしくは禁止する権利を有します。<br/>(5)登録教師は前項の場合に異議を申し立てないものとします。</p><br/>
          
          <h3>8. 個人情報の取り扱いについて</h3>
          <p>当社は、以下の目的で個人情報を利用します。<br/>(1)電子メールの送信およびその他のサイトで定めるサービスの提供<br/>(2)会員属性別への各種情報提供<br/>(3)WEB広告・WEBマーケティング活動の実施</p><br/>

          <h3>規約の始期について</h3>
          <p>本規約は、2019年3月1日より有効とします。</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Terms