import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import PriceTable from '../components/PriceTable';
import StudentButton from '../components/StudentButton';

const Price = () => {
  return (
    <div className="allContainer">
      <Header />
      <div className='titleBox'>
        <h1 className='titleBoxContent'>料金システムについて</h1>
      </div>
      <div className='priceContainer'>
        <div className='titleBlock'>
          <h2 className='title'>パーソナルは業界最低水準の料金</h2>
          <div className='sentenceBoldRed'>
            <p>当サービスで支払っていただく費用は<span>5,000円（1回限り）</span>のみです。<br/>
            家庭教師の個人契約マッチングサービスはたくさんありますが、<br/>他社と比較しても、当サービスは<span>半額</span>程度でご利用いただけます。</p>
          </div>
        <div className='titleBlock'>
          <h2 className='titleLimitedOffer'>【期間限定】紹介料が3,000円！秋のキャンペーン実施中</h2>
          <div className='sentenceBoldBlack'>
            <p>紹介料が<span>40%OFF</span>でご利用いただけるキャンペーンを実施中！<br/>条件は期間中（終了未定）に登録・マッチングを完了するだけ。この機会をお見逃しなく！<br/></p>
          </div>
        </div>
        <StudentButton />
        </div>
        <div className='titleBlock'>
          <h2 className='title'>料金について</h2>
          <div className='sentenceBoldBlack'>
            <p>パーソナルではサービスの登録料、入会金や指定教材の購入は一切ございません。</p>
          </div>
          <PriceTable />
        </div>
        <div className='titleBlock'>
          <h2 className='title'>ご登録を迷われている方へ</h2>
          <div className='sentenceBoldBlack'>
              <p>当サービスでは、教師のリストを受け取り、<br/>先生の経歴や条件などを受け取るところまでは<span>無料でお使いいただけます。</span><br/>そのため、「最終的に利用料を払うところまで使うかはわからないけれど、ひとまず登録する」ことを推奨しています。</p>
          </div>
        </div>
        <div className='titleBlock'>
          <h2 className='title'>支払い方法について</h2>
          <div className='sentenceBoldBlack'>
              <p>支払い方法は銀行振込（手数料お客様負担）のみとなります。<br/>振込先、期限などはマッチング後の運営からのメールをご確認ください。</p>
          </div>
        </div>
        <div className='titleBlock'>
          <h2 className='title'>契約書サンプルを差し上げます</h2>
          <div className='sentenceBoldBlack'>
              <p>パーソナルをご利用いただいた方には、個人契約が初めての方でも安心できるよう、<br/>「契約書サンプル」を提供しております。</p>
          </div>
        </div>
        <StudentButton />
      </div>
      <Footer />
    </div>
  )
}

export default Price