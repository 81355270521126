import React from 'react'

const PriceTable = () => {
  return (
    <div className='priceTables'>
        <div className='priceTable'>
            <p className='tabelTitleUs'>パーソナルの料金</p>
            <dl className='tableData'>
                <dt>料金</dt>
                <dd>5,000円（現在はキャンペーン中で3,000円に！）</dd>
            </dl>
            <dl className='tableData'>
                <dt>料金発生</dt>
                <dd>先生の連絡先を初回にお渡しする時（<span className='red'>何人の連絡先をお渡ししても一度きり</span>）</dd>
            </dl>
        </div>
        <div className='priceTable'>
            <p className='tabelTitle'>ほかの個人契約サービス相場</p>
            <dl className='tableData'>
                <dt>料金</dt>
                <dd>6,000円〜10,000円</dd>
            </dl>
            <dl className='tableData'>
                <dt>料金発生</dt>
                <dd>先生の連絡先を1件お渡しする時（連絡先をお渡しする時、毎回発生）</dd>
            </dl>
        </div>
        <div className='priceTable'>
            <p className='tabelTitle'>従来の家庭教師センター</p>
            <dl className='tableData'>
                <dt>料金</dt>
                <dd>6,000円</dd>
            </dl>
            <div className='tableData'>
                <dt>料金発生</dt>
                <dd><p>毎授業</p><span className='small'>※授業一回を2時間、派遣会社への支払いを相場の3,000円/時と想定した場合</span></dd>
            </div>
        </div>
        <ul className='priceNotion'>
            <li>※当サービスでは、上記利用料金以外の費用は一切かかりません。</li>
            <li>※他サービスの詳細金額はホームページ等でご確認ください。</li>
            <li>※月謝は家庭教師に直接お支払いください。</li>
            <li>※パーソナルでは、家庭教師の一方的な都合により採用ができなかった場合、受領金を全額返金いたします。</li>
        </ul>
    </div>
  )
}

export default PriceTable