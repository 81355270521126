import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

const Company = () => {
  return (
    <div>
        <Header />
        <div className='titleBox'>
            <h1 className='titleBoxContent'>運営社情報</h1>
        </div>
        <div className='companyContainer'>
            <div className='companySection'>
                <h2 className='companyHeading'>名称</h2>
                <p className='companyContent'>家庭教師のパーソナル</p>
            </div>
            <div className='companySection'>
                <h2 className='companyHeading'>運営者名</h2>
                <p className='companyContent'>代表：樋口 和樹</p>
            </div>
            <div className='companySection'>
                <h2 className='companyHeading'>事業内容</h2>
                <p className='companyContent'>家庭教師の個人契約マッチングサービスの開発、運営</p>
            </div>
            <div className='companySection'>
                <h2 className='companyHeading'>所在地</h2>
                <p className='companyContent'>〒142-0043 東京都品川区二葉2-23-6</p>
            </div>
            <div className='companySection'>
                <h2 className='companyHeading'>連絡先</h2>
                <p className='companyContent'>当サービスのお問い合わせフォームまたはお電話（070-8403-9468）<br/>お電話は繋がりにくい場合がございます。フォームからのお問い合わせを優先的に対応させていただきます。</p>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Company