import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import howTo from "../../Images/howTo.jpg";
import ArticleListWithout0006 from '../../components/ArticleList/ArticleListWithout0006'
import NewRegistration from '../../components/NewRegistration';
import PriceTable from '../../components/PriceTable';
import NewRegistars from '../../components/NewRegistars';
import Voice from '../../components/Voice';
import StudentButton from '../../components/StudentButton';

const Article0006 = () => {
  return (
    <div>
      <Header />
        <div className='articlePage'>
          <div className='articleContainer'>
            <div className='articleContent'>
              <p className='articleDate'>2022年10月14日</p>
              <h1 className='articleTitle'>【生徒向け完全ガイド】家庭教師の個人契約はどこで見つけられる？</h1>
              <h2 className='articleEyecatch'><img src={howTo} alt="" width="100%" /></h2>
              <h3 className='toc'>
                <ul>
                  <li>【目次】</li>
                  <li>1. そもそも家庭教師の個人契約とは何か？メリットは？</li>
                  <li>2. 個人契約の先生と出会う方法</li>
                  <li>3. 効率よく、時給が安くて優秀な先生と出会うには？</li>
                  <li>4. トラブルを回避するために</li>
                </ul>
              </h3>
              <div className='articleBody'>
                <h3 className='articleLead'>
                  <p>そもそも家庭教師の個人契約とは何か？メリットは？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'><p>家庭教師の個人契約とは、先生と生徒が"直接"契約をすることです。これまでの家庭教師は一般的に、"家庭教師センター"と呼ばれるような仲介業者を通して、先生と生徒の契約が行われていました。<br/><br/>しかし最近では、先生・生徒両方にとって金銭的なメリットが大きい個人契約が主流になりつつあります。</p>
                  </div>
                  <div className='fyi'>
                    <p className='heading'>補足：家庭教師センターとは？</p>
                    <p className='text'>家庭教師センターとは家庭教師派遣会社のことです。<br/>家庭教師センターは、個人契約に比べて授業料は高い傾向にあります。その理由としては、講師への給料に加え、マージン料、広告費、講師募集費用などが、ご家庭が払う月謝に含まれるからです。その他に、入会金や管理費がかかるため個人契約に比べると割高になるでしょう。<br/><br/>ご家庭にとって値段が高いのはネックですがもちろんそれ相応の価値もあります。たとえば、家庭教師の個人契約で最もデメリットとしてあげられるのはご家庭生徒間でのトラブルです。センターではトラブルに対する対応を一括に請け負ってもらえるため、ご家庭の負担や煩わしさは大きく軽減されるでしょう。</p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>個人契約の先生と出会う方法</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                      個人契約の家庭教師を探す方法は大きく２つあります。<br/><br/>
                      <h4>知人や友人からの紹介</h4><br/>
                      知人や友人からの紹介は、昔から行われている方法です。例えば、年齢の近いお子さんがいる友人から先生を紹介してもらったり、親戚から紹介してもらったりなどのケースです。しかし、なかなか都合良く先生が見つけるケースも少なく、先生の候補が一人では条件が合わない場合もあります。<br/><br/>
                      <h4>マッチングサイトの活用</h4><br/>
                      家庭教師に特化したマッチングサイトなどを活用して探す方法なら、たくさんの先生の候補から探すことができます。地域、時給、科目、性別、年齢など様々な条件からマッチングすることができます。マッチングサイトはいくつも存在し、料金システムもまちまちです。オススメの使い方をこの後紹介します。
                    </p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>効率よく、時給が安くて優秀な先生と出会うには？</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                    個人契約の先生を探すなら、出来るだけ時給が安くて優秀な先生と契約したいと思うのが当然ですよね。そのためには、たくさんの先生候補と出会うことが大事です。では、たくさんの候補と出会うにはマッチングサイトをどのように使えば良いでしょうか？<br/><br/>
                    <h4>1. 内容はコピペでいいので多くのサイトに登録する</h4><br/>
                    なぜかというと、マッチングサイトのほとんどは、登録〜先生のプロフィールを閲覧するまでは無料なサービスだからです。少し面倒かもしれませんが、良い条件の先生を探すにはまず、登録数を増やすことでたくさんの先生候補を見つけることが大事だと言えます。<br/><br/>
                    <h4>2. 契約するサイトを選ぶ</h4><br/>
                    効率よく個人契約するためには、先生の条件だけではなく最終的に利用するマッチングサイトを選ぶ必要があります。正直、提供しているサービス内容にほとんど差はありません。選ぶ際に見るべきポイントはこの二つです。<br/><br/>
                    <span className='black'>・いつ料金が発生するのか<br/>
                    ・その料金はいくらか？<br/><br/></span>
                    例えば、個人契約のマッチングサイトで主流なのは、<span className='black'>連絡先一件あたり●●円</span>というものです。「一度きり」などサイトに書かれていても、実際は先生を紹介してもらうごとに毎回料金がかかります。<br/><br/>
                    大まかな料金表は以下の通りです。一番上のサービスはいきなり宣伝となり大変恐縮ですが、我々の提供しているサービスです。先生を何人紹介しても、5,000円（<span className='red'>今はキャンペーン中で3,000円</span>）ですので、他社様よりかなりお安く使っていただけると思います。各社の料金の詳細は、ご自身で登録ついでに調べていただければと思いますが、相場は大手含めて6,000〜10,000円程度です。
                    <PriceTable />
                    </p>
                  </div>
                </div>
                <h3 className='articleLead'>
                  <p>トラブルを回避するために</p>
                </h3>
                <div className='articleText'>
                  <div className='mainSentence'>
                    <p>
                      最後に、家庭教師の個人契約をする際の注意点です。<br/><br/>
                      <h4>基本的にトラブルは自己解決しなくてはならない</h4><br/>
                      起きることは稀ですが、もし先生とのトラブルが起きた場合は自己解決しなくてはいけません。例えば、スケジュール調整、遅刻、急遽のキャンセル、成績についてです。特に成績については、塾の勉強を補うためや、定期試験の点数が少しでも上がれば、という程度でしたらトラブルは起きづらいですが、家庭教師に責任の比重が大きくかかるような指導方針はトラブルの元です。そういった場合は、できる限り大手の家庭教師センターを利用されることを検討してみてはいかがでしょうか。<br/><br/>
                      <h4>自分で生徒を探す必要がある</h4><br/>
                      すでに紹介はしましたが、何と言ってもここのハードルが高いです。弊社でもかなり慎重に先生の審査はしていますが、オンラインのマッチングである性質上、プロフィールだけでその人の全てはわかりません。何人もの先生と会っても大手ほどの期間はかからないと思いますので、慎重に家庭教師を探しましょう。
                    </p>
                  </div>
                  <StudentButton />
                </div>
              </div>
            </div>
            <div className='rightContents'>
              <ArticleListWithout0006 />
              <NewRegistration />
              <div className='pcArea'><NewRegistars /></div>
              <Voice />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  )
}

export default Article0006