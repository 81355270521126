import React from 'react'
import { Button } from './Button';
import { GoTriangleRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

const TeacherButton = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/registration-teacher", {state: {id: 1}})
  }
  return (
    <div className='buttonTeacherRegistration'>
      <Button onClick={handleClick} type="button" id="trb"
      buttonStyle="btn--teacherRegistration"
      >家庭教師として登録する（無料）<GoTriangleRight className='icon' /></Button>
    </div>
  )
}

export default TeacherButton