import React from 'react'
import { Link } from "react-router-dom";
import Footer from '../components/Footer'
import Header from '../components/Header'

const Sitemap = () => {
  return (
    <div>
        <Header />
        <div className='titleBox'>
            <h1 className='titleBoxContent'>サイトマップ</h1>
        </div>
        <div className='sitemapContainer'>
            <div className='sitemapSection'>
                <Link to="/#top" className='sitemapSection' style={{ textDecoration: 'none' }}>
                    <h2>生徒用ページ</h2>
                </Link>
                <ul className='sectionList'>
                    <Link to="/registration-student" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>生徒情報登録ページ</li>
                    </Link>
                    <Link to="/teacher_search" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>家庭教師の先生をさがす</li>
                    </Link>
                    <Link to="/teacherpage" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>家庭教師プロフィールページ</li>
                    </Link>
                    <Link to="/questions" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>よくあるご質問</li>
                    </Link>
                    <Link to="/price" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>料金システムについて</li>
                    </Link>
                </ul>
            </div>
            <div className='sitemapSection'>
                <Link to="/teacher" className='sitemapSection' style={{ textDecoration: 'none' }}>
                    <h2>家庭教師用ページ</h2>
                </Link>
                <ul className='sectionList'>
                    <Link to="/registration-teacher" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>家庭教師情報登録ページ</li>
                    </Link>
                    <Link to="/recruit_search" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>募集中の家庭教師案件をさがす</li>
                    </Link>
                    <Link to="/recruit" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>求人情報ページ</li>
                    </Link>
                </ul>
            </div>
            <div className='sitemapSection'>
                <Link to="/article" className='sitemapSection' style={{ textDecoration: 'none' }}>
                    <h2>家庭教師や学習に関する記事</h2>
                </Link>
                <ul className='sectionList'>
                    <Link to="/article/personal-contract" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>家庭教師の個人契約とは？</li>
                    </Link>
                    <Link to="/article/market-price" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>家庭教師の費用相場は？</li>
                    </Link>
                    <Link to="/article/how-to" className='sectionContent' style={{ textDecoration: 'none' }}>
                        <li>【生徒向け完全ガイド】家庭教師の個人契約のはじめ方</li>
                    </Link>
                </ul>
            </div>
            <div className='sitemapSection'>
                <Link to="/reissue" className='sitemapSection' style={{ textDecoration: 'none' }}>
                    <h2>登録した情報の編集（生徒・家庭教師）</h2>
                </Link>
            </div>
            <div className='sitemapSection'>
                <Link to="/contact" className='sitemapSection' style={{ textDecoration: 'none' }}>
                    <h2>お問い合わせ</h2>
                </Link>
            </div>
            <div className='sitemapSection'>
                <Link to="/terms" className='sitemapSection' style={{ textDecoration: 'none' }}>
                    <h2>プライバシーポリシー</h2>
                </Link>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Sitemap