import React from 'react'
import { Button } from './Button';
import { GoTriangleRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

const StudentButton = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/recruit_search", {state: {id: 1}})
  }
  return (
    <div className='buttonStudentRegistration'>
      <Button onClick={handleClick} type="button" id="srb"
      buttonStyle="btn--studentRegistration"
      >先生を探す（無料）<GoTriangleRight className='icon' /></Button>
  </div>
  )
}

export default StudentButton