import React from 'react'
import TeacherGray from "../Images/TeacherGray.png";
import HouseGray from "../Images/HouseGray.png";

const NewRegistars = () => {
  return (
    <div className='newRegistarsContainer'>
      <div className='heading'>
        <p>＼ 2022年の累計登録者 ／</p>
      </div>
      <div className='boxContent'>
        <div className='number'>
          <img src={HouseGray} alt="" />
          <div className='numbers'>
            <p>生徒様</p>
            <p>約3,600名</p>
          </div>
        </div>
        <div className='number'>
          <img src={TeacherGray} className="" alt="" />
          <div className='numbers'>
            <p>家庭教師の方</p>
            <p>約8,500名</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewRegistars