import React from 'react'
import { FooterData } from './FooterData';
import CompanyLogo from "../Images/CompanyLogo.png";
import { NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='footerContainer'>
        <div className=''>
            <ul className='footer'>
                {FooterData.map((value, key) =>{
                    return (
                        <div>
                            <NavHashLink to={value.link} style={{ textDecoration: 'none' }}>
                                <li className='footerList' key={key}>
                                    <div id="title">{value.title}</div>
                                </li>
                            </NavHashLink>
                        </div>
                    )
                })}
            </ul>
        </div>
        <div className='footerLogo'>
            <Link to="/#top" className='' style={{ textDecoration: 'none' }}>
            <img src={CompanyLogo} className="CompanyLogo" alt="" />
            </Link>
        </div>
    </div>
  )
}

export default Footer