import React from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Footer from '../components/Footer';
import Header from '../components/Header';
import Nav from '../components/Nav';
import ArticleList from '../components/ArticleList/ArticleList';
import NewRegistration from '../components/NewRegistration';
import HubspotForm from 'react-hubspot-form'

const TeacherPage = () => {
    const [quotedTeacherDatas,setQuotedTeacherDatas] = useState([]);
    useEffect(() => {
      fetch("https://sheets.googleapis.com/v4/spreadsheets/1-xI2hmRWSTpZTmCFvWk0NFSgMTTkWOvS1jaVad0WSLA/values/[検索データ]先生情報?key=AIzaSyATl2iwtpMqRw5tpImPnMNtSlPDTqVGtmE")
      .then(res => res.json())
      .then(data => setQuotedTeacherDatas(data))
    },[])
    const TeacherDatas = [];
    const [searchParams] = useSearchParams();
    for(var i = 1; i < quotedTeacherDatas.values.length; i++){
      const thisDatas = {};
      thisDatas[quotedTeacherDatas.values[0][0]] = quotedTeacherDatas.values[i][0];
      thisDatas[quotedTeacherDatas.values[0][1]] = quotedTeacherDatas.values[i][1];
      thisDatas[quotedTeacherDatas.values[0][2]] = quotedTeacherDatas.values[i][2];
      thisDatas[quotedTeacherDatas.values[0][3]] = quotedTeacherDatas.values[i][3];
      thisDatas[quotedTeacherDatas.values[0][4]] = quotedTeacherDatas.values[i][4];
      thisDatas[quotedTeacherDatas.values[0][5]] = quotedTeacherDatas.values[i][5];
      thisDatas[quotedTeacherDatas.values[0][6]] = quotedTeacherDatas.values[i][6];
      thisDatas[quotedTeacherDatas.values[0][7]] = quotedTeacherDatas.values[i][7];
      thisDatas[quotedTeacherDatas.values[0][8]] = quotedTeacherDatas.values[i][8];
      thisDatas[quotedTeacherDatas.values[0][9]] = quotedTeacherDatas.values[i][9];
      thisDatas[quotedTeacherDatas.values[0][10]] = quotedTeacherDatas.values[i][10];
      thisDatas[quotedTeacherDatas.values[0][11]] = quotedTeacherDatas.values[i][11];
      thisDatas[quotedTeacherDatas.values[0][12]] = quotedTeacherDatas.values[i][12];
      thisDatas[quotedTeacherDatas.values[0][13]] = quotedTeacherDatas.values[i][13];
      thisDatas[quotedTeacherDatas.values[0][14]] = quotedTeacherDatas.values[i][14];
      thisDatas[quotedTeacherDatas.values[0][15]] = quotedTeacherDatas.values[i][15];
      thisDatas[quotedTeacherDatas.values[0][16]] = quotedTeacherDatas.values[i][16];
      thisDatas[quotedTeacherDatas.values[0][17]] = quotedTeacherDatas.values[i][17];
      thisDatas[quotedTeacherDatas.values[0][18]] = quotedTeacherDatas.values[i][18];
      thisDatas[quotedTeacherDatas.values[0][19]] = quotedTeacherDatas.values[i][19];
      thisDatas[quotedTeacherDatas.values[0][20]] = quotedTeacherDatas.values[i][20];
      thisDatas[quotedTeacherDatas.values[0][21]] = quotedTeacherDatas.values[i][21];
      thisDatas[quotedTeacherDatas.values[0][22]] = quotedTeacherDatas.values[i][22];
      TeacherDatas.push(thisDatas)
    }
    const filteredTeacherDatas = TeacherDatas.filter(TeacherDatas => TeacherDatas.id === searchParams.get("id"))
  
  return (
      <div className='teacherPageContainer'>
        <Header />
        <div className='pcArea'><Nav /></div>
        <div className='mainSection'>
            <div className='leftContents'>
              <div className='pcArea'><NewRegistration /></div>
            </div>
            <div className='mainContents'>
              <div className='introduction'>
                <h1>家庭教師をお探しの方へ</h1>
                <p>パーソナルは、入会金・解約金・管理費・教材販売他一切無しの<span>3,000円で先生を何人でも選び放題。</span></p><br/>
                <p>・パーソナルは<span>業界最安水準</span>の家庭教師個人契約サイトです。</p>
                <p>・パーソナルなら授業料が平均して<span>4〜5割安く</span>なります。</p>
                <p>・パーソナルなら担当の先生を<span>何人でも自分で選ぶ</span>ことができます。</p>
              </div>
              <div className='recruitInfo'>
                  <div className='tableTitle'>先生のプロフィール</div>
                  {filteredTeacherDatas.map((value, key) => {
                      return (
                          <div className='tableItems'>
                              <dl className='tableItem'>
                                  <dt>教師ID</dt>
                                  <dd>{value.id}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>名前</dt>
                                  <dd>{value.name}先生</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>年齢</dt>
                                  <dd>{value.age}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>性別</dt>
                                  <dd>{value.sex}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>最終学歴</dt>
                                  <dd>{value.final_edu}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>職業</dt>
                                  <dd>{value.status}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>指導可能都道府県</dt>
                                  <dd>{value.possible_prefecture}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>指導可能市区町村</dt>
                                  <dd>{value.possible_city}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>最寄駅</dt>
                                  <dd>{value.station}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>指導可能科目</dt>
                                  <dd>{value.subject}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>指導可能日時</dt>
                                  <dd>{value.days}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>希望時給</dt>
                                  <dd>{value.wage}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>出身中学校</dt>
                                  <dd>{value.jhs}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>出身高校</dt>
                                  <dd>{value.hs}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>指導経験</dt>
                                  <dd>{value.experience}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>自己紹介</dt>
                                  <dd>{value.profile}</dd>
                              </dl>
                              <dl className='tableItem'>
                                  <dt>プロフィール更新日</dt>
                                  <dd>{value.update}</dd>
                              </dl>
                          </div>
                      )
                  })}
              </div>
              <div className='apply'>
                <h1>この先生に仮申し込みする</h1>
                  <div className='heading'>
                    <h2>※仮申し込みするとどうなる？</h2>
                    <p>・仮申し込みをすると、先生に指導可能かどうか、またスケジュールの確認を運営が行います。</p>
                    <p>・何人でも、無料で仮申し込みが可能です。</p>
                    <p>・先生が指導可能だった場合、ご登録のメールアドレスにご連絡いたします。その後の正式な申し込みまでの流れは、メールをご確認ください。</p>
                  </div>
                  <div className='heading'>
                    <h2>生徒情報の登録がお済みでない方</h2>
                    <p>仮申し込みには、<span>生徒情報の登録が必要</span>となります。<br/>
                    まずは<Link to="/registration-student" target="_blank" className='toLink'>生徒情報登録ページ</Link>よりご登録をお願いいたします。</p>
                  </div>
                  <div className='heading'>
                    <h2>生徒情報の登録がお済みの方</h2>
                    <p>以下のフォームより、仮申し込みをしてください。仮申し込みが完了後、1日程度で確認メールをお送りしますのでご確認ください。</p>
                    <div className='hubspotform'>
                      <HubspotForm
                        portalId='22770219'
                        formId='f4b2c570-973e-4430-8b9b-9072cce6531a'
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                      />
                    </div>
                    <p>※生徒IDを忘れた場合<br/>
                    情報登録をされた際の、運営からの完了メールをご確認ください。<br/>もしくは、求人検索ページで検索をして、ご自身の情報と一致するページが見つかれば、そちらに記載されている生徒IDがあなたの生徒IDです。</p>
                  </div>
              </div>
            </div>
            <div className='rightContents'>
              <ArticleList />
            </div>
          </div>
        <Footer />
      </div>
    )
  
  }

export default TeacherPage