export const  QuestionData = [
    {
        questionTheme: "個人契約と通常の契約の違いを教えてください。",
        answerTheme: "個人契約とは、家庭教師センターを仲介せずに、生徒様と先生とで直接契約をする方式です。家庭教師センターが仲介に入らない分、生徒側の支払金額を抑えることができますし、その一部を先生の時給に回すことでより好条件で先生を探すこともできます。",
    },
    {
        questionTheme: "家庭教師センターと、先生の質の違いはありますか？",
        answerTheme: "一概には言えませんが、はっきり申し上げると質は同じと考えております。登録している先生方は、生徒様を探すために多数の業者に登録しているケースがほとんどですし、昨今はオンラインのマッチングの需要が増えていますから、「仲介があるから安心」「個人契約だから優秀」などといった差はないかと思います。",
    },
    {
        questionTheme: "パーソナルはなぜ利用料金が安いのですか？",
        answerTheme: "当サービスが安い理由は2点ございます。1点目は「仲介業者がいないため」です。\n仲介業者が入る場合は通常、授業ごとに手数料（授業料の70-100%程度が相場）が発生します。パーソナル（をはじめとする個人契約サービス）は、その手数料がない分安いです。料金比較表は下記ページをご覧ください。\nもちろん、仲介業者を使うことで得られるメリットもあり、指導方針が合わないなど何かトラブルがあった際には仲介業者が代理の先生を立ててくれたり、授業枠を補填してくれたりします。ですが、その分先生を何人か個人契約してもお釣りが返ってくる計算であることがほとんどなため、個人契約が主流になりつつあるのだと思います。\n\n2点目は「運営を効率化しているため」です。\n弊社では、本サービス以外にもAIを活用した事業やWebサイトの運営を行なっております。そのため、個人契約をサポートする同業者の中でも、特に割安な料金でサービス提供をさせていただいております。",
        reference: ">> 料金比較",
        link: "/price",
    },
    {
        questionTheme: "紹介料の5,000円以外にかかる料金はありますか？",
        answerTheme: "紹介料以外にかかる料金はございません。",
    },
    {
        questionTheme: "相性のいい先生は見つかりますか？",
        answerTheme: "相性のいいマッチングとなるよう、生徒、家庭教師には多くの情報をご登録いただいております。家庭教師を選ぶ際には、実績や自己紹介の他に、マッチング前に追加で質問していただくこともできます。",
    },
    {
        questionTheme: "申し込みから指導までの流れを教えてください。",
        answerTheme: "下記ページをご確認ください。それでもわからないことがある場合はサイト下部のお問い合わせフォームよりご連絡くださいませ。",
        reference: ">> 指導までの流れ",
        link: "/#guide",
    },
    {
        questionTheme: "先生の見つけ方を教えてください。",
        answerTheme: "生徒登録していただいた方には週数回、先生情報のメールをお送りしております。そちらからご希望の先生をお探しください。",
    },
    {
        questionTheme: "先生はどれくらいいますか？",
        answerTheme: "年に4,000名程度のペースで登録がされています。",
    },
    {
        questionTheme: "登録から紹介まで平均日数は大体どのくらい？",
        answerTheme: "早い方ですと1日、平均で1週間程度です。募集条件により異なりますので、あくまでご参考としていただければ幸いです。",
    },
    {
        questionTheme: "どんな先生がいますか？",
        answerTheme: "実績の豊富なプロ家庭教師や、医学部や難関大学に入学した先生が活躍されています。個人契約での指導を希望する先生には家庭教師の経験者が多いのが特長です。",
    },
    {
        questionTheme: "実績はありますか？",
        answerTheme: "毎月数百名の生徒様に先生をご紹介しております。",
    },
    {
        questionTheme: "生徒情報の登録に料金はかかりますか？",
        answerTheme: "生徒情報の登録は無料です。",
    },
    {
        questionTheme: "マッチング後の面談はどこで行なうのですか？",
        answerTheme: "特に決まりはございませんが、一般的なのは、生徒様のご自宅やご自宅近くのカフェ・飲食店などです。",
    },
    {
        questionTheme: "面談や体験授業をしてから先生と契約することは可能ですか？",
        answerTheme: "可能です。ただし、先生の連絡先をお渡しするタイミングで当サービスの紹介料5,000円は発生致しますので、ご注意ください。",
    },
    {
        questionTheme: "複数名の先生を指名する場合は、その都度料金が発生しますか？",
        answerTheme: "発生致します。お客様の声によれば、概ね1-2名の先生指名で本指導開始までいたるご家庭がほとんどです。\nなお、家庭教師側の一方的な都合で採用が不可能になった場合に限り、全額返金をさせていただきます。",
    },
    {
        questionTheme: "個人契約をやったことが無いので不安です。",
        answerTheme: "個人契約がはじめての方でも安心できるよう、パーソナルを利用頂いた方には、 下記の個人契約締結までの手引きを提供させていただいております。\n・面談の日時・場所決定について\n・面談の準備、話すべきことについて\n・先生の採用基準\n・指導開始後について",
    },
    {
        questionTheme: "登録情報の変更・削除はどうすれば良いですか？",
        answerTheme: "登録情報の変更は以下の通りです。ご登録完了の際、ご記入いただいたメールアドレス宛に「Googleフォーム（forms-receipts-noreply@google.com）」からメール届いております。メール内に、【回答を編集】というボタンがございますので、そちらから情報の編集をお願いいたします。\n削除をご希望の方は、こちらのお問い合わせフォームから「登録情報について」を選択し、送信してください。",
        reference: ">> お問い合わせフォーム",
        link: "/contact",
    },
    {
        questionTheme: "営業日はいつですか？",
        answerTheme: "365日営業しております。そのため、長期休み期間などでも速やかに先生のご紹介が可能です。",
    },
    {
        questionTheme: "個人情報はどのように取り扱っていますか？",
        answerTheme: "住所、電話番号、メールアドレス等、個人を特定できるような情報がインターネット上で公開されることは一切ございませんのでご安心ください。登録フォームの「公開」がついている項目のみ情報が公開されますので、個人が特定されるような情報は書かないようにお願いします。また、個人情報は家庭教師とのマッチング以外の目的で第三者に開示することはありません。",
    },
    {
        questionTheme: "電話での申し込みはできますか？",
        answerTheme: "申し訳ありません。電話でのお申込みは受け付けておりません。",
    },
    {
        questionTheme: "運営会社に連絡を取りたい場合はどうすれば良いですか？",
        answerTheme: "質問事項等、弊社まで連絡をとりたい場合は、お問い合わせフォームよりご連絡いただきますようにお願いいたします。",
        reference: ">> お問い合わせフォーム",
        link: "/contact",
    },
];