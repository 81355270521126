import React from 'react'
import { NavHashLink } from "react-router-hash-link";

const Nav = () => {
  return (
    <nav className='headerNav'>
        <ul className='navUnit'>
          <li className='navList'>
            <p className='navTitle'>家庭教師の個人契約とは</p>
            <ul className='dropdownLists'>
              <NavHashLink to="/article/personal-contract">
                <li className='dropdownList'><p>家庭教師の個人契約とは？</p></li>
              </NavHashLink>
              <NavHashLink to="/article/market-price">
                <li className='dropdownList'><p>お金はどのくらいかかる？</p></li>
              </NavHashLink>
              <NavHashLink to="/article/how-to">
                <li className='dropdownList'><p>まず何をすればいいの？</p></li>
              </NavHashLink>
            </ul>
          </li>
          <li className='navList'>
            <p className='navTitle'>料金システム</p>
            <ul className='dropdownLists'>
              <NavHashLink to="/price">
                <li className='dropdownList'><p>パーソナルの料金システム</p></li>
              </NavHashLink>
            </ul>
          </li>
          <li className='navList'>
            <p className='navTitle'>お客様の声</p>
            <ul className='dropdownLists'>
                <NavHashLink to="/#voice">
                    <li className='dropdownList'><p>お客様の声</p></li>
                </NavHashLink>
            </ul>
          </li>
          <li className='navList'>
            <p className='navTitle'>ご利用方法</p>
            <ul className='dropdownLists'>
              <NavHashLink to="/#guide">
                <li className='dropdownList'><p>生徒として利用する</p></li>
              </NavHashLink>
              <NavHashLink to="/registration-teacher">
                <li className='dropdownList'><p>家庭教師として利用する</p></li>
              </NavHashLink>
            </ul>
          </li>
          <li className='navList'>
            <p className='navTitle'>よくあるご質問</p>
            <ul className='dropdownLists'>
              <NavHashLink to="/questions">
                <li className='dropdownList'><p>よくあるご質問</p></li>
              </NavHashLink>
              <NavHashLink to="/contact">
                <li className='dropdownList'><p>お問い合わせ</p></li>
              </NavHashLink>
            </ul>
          </li>
        </ul>
      </nav>
  )
}

export default Nav