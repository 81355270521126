import React from 'react'
import { QuestionData } from '../components/QuestionData'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { NavHashLink } from "react-router-hash-link";
import StudentButton from '../components/StudentButton';

const Questions = () => {
    return (
    <div className=''>
        <Header />
        <div className='titleBox'>
            <h1 className='titleBoxContent'>よくあるご質問</h1>
        </div>
        <ul className='questionsContainer'>
            {QuestionData.map((value, key) => {
                return(
                    <li className='questionsContent' key={key} style={{whiteSpace: 'pre-line'}}>
                        <div className='questionSentence'>
                            <p className='questionIcon'>Q.</p>
                            <p className='questionTheme'>{value.questionTheme}</p>
                        </div>
                        <div className='questionSentence'>
                            <p className='answerIcon'>A.</p>
                            <p className='answerTheme'>{value.answerTheme}</p>
                        </div>
                        <NavHashLink to={value.link} className='referenceSentence' style={{ textDecoration: 'none' }}>{value.reference}</NavHashLink>
                    </li>
                )
            })}
            <StudentButton />
        </ul>
        <Footer />
    </div>
  )
}

export default Questions