import React from 'react'
import { TeacherGuideData } from './TeacherGuideData';
import TeacherButton from './TeacherButton';

const StudentGuide = () => {
  return (
    <div className='teacherGuideContainer'>
        <div className='titleBlock'>
        <h2 className='title'>（登録後）生徒様との個人契約までの流れ</h2>
            <ul className='guideContent'>
                {TeacherGuideData.map((value, key) => {
                    return (
                        <li className='guideListItem' key={key}>
                            <div className='guideContentLeft'>
                                <h3 className='guideListTitle'>
                                    <div id="number">{value.number}</div>
                                    <div id="title">{value.title}</div>
                                </h3>
                                <div id="description">{value.description}</div>
                            </div>
                            <div className='guideImgs'>
                                <img src={`images/${value.guideImg}.png`} className="guideImg" alt="" />
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
        <TeacherButton />
    </div>
  )
}

export default StudentGuide